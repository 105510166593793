<div class="modal-content content border-0">
    <div class="modal-header">
      <h1 class="modal-title fw-bold ms-auto me-auto">Registrar no Painel de Cliente</h1>
    </div>
    <div class="modal-body">
        <form (ngSubmit)="onSubmit()" #form="ngForm">
            <div class="form-group">
                <label>Nome</label>
                <input 
                class="form-control" 
                type="text" 
                name="name" 
                id="name" 
                required />
            </div>
            <div class="form-group">
                <label>E-mail</label>
                <input 
                class="form-control" 
                type="email" 
                name="email" 
                id="email" 
                required />
            </div>
            <div class="form-group">
                <label>Senha</label>
                <input
                class="form-control" 
                type="password" 
                name="password" 
                id="password"
                required />
            </div>
            <div class="d-block">
              <button 
              [style]="getColorButtonRegistrar()" 
              class="btn --btn-envio" 
              type="submit">Registrar</button>
              <button 
              [style]="getColorButtonVoltar()" 
              class="btn --btn-cancel"
              type="button"
              [routerLink]="['/login']"
              >Voltar</button>
            </div>
        </form>
    </div>
</div>
