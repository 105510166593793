<div class="container">  
    <div class="row">
      <div class="col-md-2">
        <button class="btn btn-warning" (click)="novoCadastro()">
          <i class="fa fa-plus"></i>
          Novo
        </button>
      </div>
    </div>
    <br />
    <mat-form-field appearance="standard">
      <mat-label>Filter</mat-label>
      <input
        matInput
        (keyup)="applyFilter($event)"
        placeholder="Ex. Lucas"
        #input
      />
    </mat-form-field>
  
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort>
  
        <!-- Name Column -->
        <ng-container matColumnDef="descricao">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>descricao</th>
          <td mat-cell *matCellDef="let row">{{ row.descricao }}</td>
        </ng-container>
  
        <!-- Weight Column -->
        <ng-container matColumnDef="cliente">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Cliente</th>
          <td mat-cell *matCellDef="let row">{{ row.cliente }}</td>
        </ng-container>
  
        <!-- Symbol Column -->
        <ng-container matColumnDef="usuario">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuário</th>
          <td mat-cell *matCellDef="let row">{{ row.usuario }}</td>
        </ng-container>
  
        <!-- Symbol Column -->
        <ng-container matColumnDef="dataAbertura">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Data de Abertura</th>
          <td mat-cell *matCellDef="let row">{{ row.dataAbertura | date: "dd/MM/yyyy" }}</td>
        </ng-container>
  
        <!-- Symbol Column -->
        <ng-container matColumnDef="acoes">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Acões</th>
          <td mat-cell *matCellDef="let row">
            <button
              class="btn btn-primary"
              routerLink="/solicitacao/form/{{ row.id }}"
            >
              <i class="fa fa-edit"></i>
            </button>
            <button
              class="btn btn-danger"
              (click)="preparaDelecao(row)"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <i class="fa fa-trash"></i>
            </button>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filter</td>
        </tr>
      </table>
  
      <mat-paginator
        [pageSizeOptions]="[15, 20, 25, 50]"
        showFirstLastButtons
        aria-label="Select page of periodic rows"
      >
      </mat-paginator>
    </div>
  
    <div class="modal fade" id="exampleModal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Modal title</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            Confirma a deleção do(a) solicitacao?
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Cancelar
            </button>
            <button
              type="button"
              (click)="deletarSolicitacao()"
              data-bs-dismiss="modal"
              class="btn btn-primary"
            >
              Deletar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>