export class Socio {
    id: string; 
    nome: string;
    CPF: number;
    CEP: number;
    endereco: string;
    bairro: string;
    cidade: string;
    estado : string;
    contato: number;
}