<app-navbar></app-navbar>
<div id="layoutSidenav">
    <app-sidebar></app-sidebar>
    <div id="layoutSidenav_content">
        <main>
            <div class="container-fluid">
                <router-outlet></router-outlet>
            </div>
        </main>
    </div>
</div>
<footer class="footer">
    <div class="container-fluid">
        <div class="d-flex align-items-center justify-content-between small">
            <div class="text-muted">Copyright &copy;</div>
            <div>
                <a href="#">Privacy Policy</a>
                &middot;
                <a href="#">Terms &amp; Conditions</a>
            </div>
        </div>
    </div>
</footer>
